/* Floor plan pins */
.amt-floor-plan--pin-inner:not([aria-disabled="true"]) {
  cursor: pointer;
}

.amt-floor-plan--pin .hover-rotate {
  transform-box: fill-box;
  transform-origin: center;
}

.amt-floor-plan--pin .icon--available,
.amt-floor-plan--pin .icon--partially-available,
.amt-floor-plan--pin .icon--not-available,
.amt-floor-plan--pin .icon--conference-room,
.amt-floor-plan--pin .icon--benefit,
.amt-floor-plan--pin .icon--invite,
.amt-floor-plan--pin .icon--dedicated {
  visibility: hidden;
}

.icon--partially-available {
  color: #fd9f00;
}

[aria-disabled="true"] .icon--partially-available {
  color: #fed999;
}

.icon--not-available {
  color: #141110;
}

[aria-disabled="true"] .icon--not-available {
  color: #d0cfcf;
}

.amt-floor-plan--pin .icon--partially-available,
.amt-floor-plan--pin .icon--invite,
.amt-floor-plan--pin .icon--not-available {
  transform: translate(12px, 12px);
}

[aria-disabled="true"] .avatar-placeholder {
  opacity: 0.65;
}

/* Available desk pins */
.amt-floor-plan--pin-desk-available,
.amt-floor-plan--pin-invite {
  color: #00cc99;
}

.amt-floor-plan--pin-desk-available:hover,
.amt-floor-plan--pin-invite {
  color: #02b689;
}

.amt-floor-plan--pin-desk-available .icon--available {
  visibility: visible;
}

.amt-floor-plan--pin-conference-room .icon--conference-room {
  visibility: visible;
}

.amt-floor-plan--pin-dedicated .icon--dedicated {
  visibility: visible;
}

.amt-floor-plan--pin-invite .icon--invite {
  visibility: visible;
}

.amt-floor-plan--pin-invite .icon--available {
  visibility: hidden;
}

.amt-floor-plan--pin-inner:not([aria-disabled="true"]) > g {
  transition: transform 250ms ease-in-out;
}

.amt-floor-plan--pin-inner:not([aria-disabled="true"]):hover > g {
  transform: translate(0, -3px);
}

/* Stroke for selected pin */
.amt-floor-plan--pin-desk-available.amt-floor-plan--pin-selected .pin-path {
  stroke: #adefdecc;
}

/* Remove stroke pin is hovered */
.amt-floor-plan--pin-inner:not([aria-disabled="true"]):hover .pin-path {
  stroke: transparent;
}

.amt-floor-plan--pin-inner:not([aria-disabled="true"]):hover .hover-shadow {
  filter: url(#filter0_d_757_16118);
}

/* Pin booked for current user */
.amt-floor-plan--pin-booked-self {
  color: #ffa500;
}

.amt-floor-plan--pin-booked-self[aria-disabled="true"] {
  color: #ffdb99;
}

.amt-floor-plan--pin-booked-self.amt-floor-plan--pin-selected .pin-path {
  stroke: #fed999cc;
}

.amt-floor-plan--pin-booked-self:not([aria-disabled="true"]):hover {
  color: #e79602;
}

/* Pin booked for another user */
.amt-floor-plan--pin-booked-other {
  color: #da0099;
}

.amt-floor-plan--pin-booked-other[aria-disabled="true"] {
  color: #f099d6;
}

.amt-floor-plan--pin-booked-other.amt-floor-plan--pin-selected .pin-path {
  stroke: #e19eca80;
}

.amt-floor-plan--pin-booked-other:not([aria-disabled="true"]):hover {
  color: #bc0384;
}

/* Pin booked for a conference room */
.amt-floor-plan--pin-conference-room,
.amt-floor-plan--pin-booked-benefit {
  color: transparent;
  transition: 0.2s all;
}

.amt-floor-plan--pin-conference-room:not([aria-disabled="true"]):hover,
.amt-floor-plan--pin-booked-benefit:not([aria-disabled="true"]):hover {
  color: #4a18b3;
}

.amt-floor-plan--pin-conference-room .icon--conference-room {
  visibility: visible;
}

.amt-floor-plan--pin-booked-benefit .icon--benefit {
  visibility: visible;
}

/* Partially/fully unavailable */
.amt-floor-plan--pin-desk-partially-available .icon--partially-available {
  visibility: visible;
}

.amt-floor-plan--pin-desk-not-available .icon--not-available {
  visibility: visible;
}

.amt-floor-plan--pin-desk-partially-available,
.amt-floor-plan--pin-desk-partially-available:hover,
.amt-floor-plan--pin-desk-not-available,
.amt-floor-plan--pin-desk-not-available:hover {
  color: #6d6b6a;
}

.amt-floor-plan--pin-desk-partially-available[aria-disabled="true"],
.amt-floor-plan--pin-desk-not-available[aria-disabled="true"] {
  color: #d0cfcf;
}

.amt-floor-plan--pin-desk-partially-available.amt-floor-plan--pin-selected
  .pin-path,
.amt-floor-plan--pin-desk-not-available.amt-floor-plan--pin-selected .pin-path {
  stroke: #d0cfcf90;
}

/* Floor plan stairs */
.amt-floor-plan--stairs {
  cursor: pointer;
}

.amt-floor-plan--stairs:hover path[fill-opacity] {
  fill-opacity: 0.38;
}

.amt-floor-plan--stairs-disabled path[fill-opacity] {
  fill-opacity: 0.9;
}
