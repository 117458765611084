.slide-header {
  left: 0;
  transition: 0.5s left ease-in-out;
  height: 104px;
  position: absolute;
  overflow: hidden;
  display: flex;
}

.slide-header-enter {
  left: -100vw;
}

.slide-header-enter-done {
  left: -100vw;
}

.slide-header-exit {
  left: 0;
}
