body {
  min-height: 100vh;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSIxMDI0IiB2aWV3Qm94PSIwIDAgMTQ0MCAxMDI0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBvcGFjaXR5PSIwLjUiPgo8cGF0aCBkPSJNMTQ0MCA5MzguMzAyQzEzOTcuMTUgOTI4Ljc3IDEzNTUuNzQgOTEyLjM4NSAxMzE3LjA3IDg5MEw5MjQuMTc5IDY4OC4yODRDODczLjA3NCA2NjQuNDY2IDg2MC4zMjcgNjE2Ljg5MSA4OTIuNjc1IDU3MC43MTJMMTEzMi4wOCAxOTkuNTc0QzExODYuMzIgMTA1LjM1NCAxMjcyLjQzIDMzLjc2NTggMTM4MC4xOSA0Ljg5MTY0QzEzODYuOTggMy4wNzIyOSAxMzkzLjc4IDEuNDQyNzYgMTQwMC41OSAwTDE0NDAgMFY5MzguMzAyWiIgZmlsbD0iIzAwQ0M5OSIvPgo8cGF0aCBkPSJNMTQ0MCA5MzguMzAyQzEzOTcuMTUgOTI4Ljc3IDEzNTUuNzQgOTEyLjM4NSAxMzE3LjA3IDg5MEw5MjQuMTc5IDY4OC4yODRDODczLjA3NCA2NjQuNDY2IDg2MC4zMjcgNjE2Ljg5MSA4OTIuNjc1IDU3MC43MTJMMTEzMi4wOCAxOTkuNTc0QzExODYuMzIgMTA1LjM1NCAxMjcyLjQzIDMzLjc2NTggMTM4MC4xOSA0Ljg5MTY0QzEzODYuOTggMy4wNzIyOSAxMzkzLjc4IDEuNDQyNzYgMTQwMC41OSAwTDE0NDAgMFY5MzguMzAyWiIgZmlsbD0id2hpdGUiIGZpbGwtb3BhY2l0eT0iMC44NyIvPgo8cGF0aCBkPSJNODg2LjkxMSAxMDI0SDBWNzA1LjY5MkM1Mi43MjkyIDY0NC41MjQgMTIxLjk2NSA1OTkuMTMzIDIwMy4yNzIgNTc4LjA1NEMzMTEuMjYzIDU1MC4wNTggNDIxLjQ3NiA1NjkuODk0IDUxNS4xMTcgNjI1LjEzNEw5MDYuMzU4IDgzMC4wNEM5NTcuMjY3IDg1NC4yNzMgOTY5LjYyNyA5MDEuOTUgOTM2LjkwMyA5NDcuODY1TDg4Ni45MTEgMTAyNFoiIGZpbGw9IiMwMENDOTkiLz4KPHBhdGggZD0iTTg4Ni45MTEgMTAyNEgwVjcwNS42OTJDNTIuNzI5MiA2NDQuNTI0IDEyMS45NjUgNTk5LjEzMyAyMDMuMjcyIDU3OC4wNTRDMzExLjI2MyA1NTAuMDU4IDQyMS40NzYgNTY5Ljg5NCA1MTUuMTE3IDYyNS4xMzRMOTA2LjM1OCA4MzAuMDRDOTU3LjI2NyA4NTQuMjczIDk2OS42MjcgOTAxLjk1IDkzNi45MDMgOTQ3Ljg2NUw4ODYuOTExIDEwMjRaIiBmaWxsPSJ3aGl0ZSIgZmlsbC1vcGFjaXR5PSIwLjg3Ii8+CjwvZz4KPC9zdmc+Cg==);
  background-size: cover;
  background-repeat: no-repeat;
}

body.green-bg {
  background-color: #00cc99;
}

hr.floor-plan--tooltip--hr {
  max-width: 80%;
  border-color: #3e3c3b;
  text-align: left;
  margin-left: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  height: 1px;
  border-top: 0;
}
