.calendarContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}

.contentContainer {
  display: grid;
  grid-template-columns: 50px repeat(5, 1fr) 50px;
  gap: 15px;
  row-gap: 0px;
  align-items: center;
  margin-top: 15px;
  flex: 1 1 auto;
  overflow: auto;
}

.empty {
  grid-column: 7;
  grid-row-start: 1;
  grid-row-end: 15;
  height: 100%;
  width: 50%;
}

.scrollbarContainer {
  width: 100% !important;
  height: 500px !important;
}

@media only screen and (max-width: 900px) {
  .contentContainer {
    grid-template-columns: 36px 1fr 36px;
  }

  .empty {
    grid-column: 3;
  }
}
